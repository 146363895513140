// @ts-expect-error FIXME нужно как-то исправить отсутствие structuredClone в 20.10 версии ноды (мб это только у меня?) (должна быть с 17 версии, но нету)
import structuredClone from 'core-js/actual/structured-clone';
import type { Route } from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { Context } from '@nuxt/types';
import { isVue2 } from 'vue-demi';
import {
  defineNuxtPlugin,
  onGlobalSetup
} from '@nuxtjs/composition-api';

type Router = ReturnType<typeof useRouter>
type PushOrReplace = Router['push'] | Router['replace']

function promisify<T extends PushOrReplace> (fn: T): T {
  const result: T = (function (to, onComplete, onAbort) {
    // @ts-expect-error нам нужен this
    // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
    const thisAlias = this;

    return new Promise<Route>(function (resolve, reject) {
      fn.call(thisAlias, to, (route) => {
        onComplete?.(route);
        resolve(route);
      }, (err) => {
        onAbort?.(err);
        reject(err);
      }
      );
    });
  }) as T;

  return result;
}

/**
 * [Only Vue 2] Vue Router push/replace monkey-patch
 *
 * Исправляет на месте несогласованность типов и реализации push/replace у роутера
 */
function monkeyPatchVueRouter (): void {
  if (isVue2) {
    onGlobalSetup(() => {
      const router = useRouter();
      router.push = promisify(router.push);
      router.replace = promisify(router.replace);
    });
  } else {
    console.error('[/plugins/polyfills.ts] Remove this monkey-patch');
  }
}

export default defineNuxtPlugin((_nuxtApp: Context) => {
  if (!('structuredClone' in globalThis)) {
    globalThis.structuredClone = structuredClone;
  }
  monkeyPatchVueRouter();
});
