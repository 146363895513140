// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NMenu_JylJW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}@media only screen and (max-width:767.98px){.NMenu_JylJW{max-width:33rem;width:100%}.NMenu_JylJW .mainNav_YBjGT{border-top-right-radius:0}}.menuMobileHead_TE6GL{border-top-right-radius:.8rem;z-index:10}.mainNav_YBjGT{background-color:#012345;border-bottom-right-radius:.8rem;border-top-right-radius:.8rem;padding:2.4rem;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:1.6rem;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;position:relative}.mainNav_YBjGT,.menuItem_tRgbR{display:-webkit-box;display:-ms-flexbox;display:flex}.menuItem_tRgbR{-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:1rem;color:#8fa6bd;font-size:1.4rem;font-weight:600;gap:1.2rem;height:-webkit-max-content;height:-moz-max-content;height:max-content;line-height:1.4;padding:.8rem;width:100%}.menuItem_tRgbR.current_vVfnj,.menuItem_tRgbR:hover{background-color:#fafafa;color:#951b81}.icon_EgQIE{height:24px;width:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NMenu": "NMenu_JylJW",
	"mainNav": "mainNav_YBjGT",
	"menuMobileHead": "menuMobileHead_TE6GL",
	"menuItem": "menuItem_tRgbR",
	"current": "current_vVfnj",
	"icon": "icon_EgQIE"
};
module.exports = ___CSS_LOADER_EXPORT___;
