import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { StateChanger } from 'vue-infinite-loading';
import {
  MeetingResponse
} from '@/types/Meeting';
import type {
  PageInfo
} from '@/types/PageInfo';
import { PaginatedItemsResponse } from '@/types/Response';

type StagesKeys =
  'MEETING_SIGN_UP' |
  'MEETING_CANCEL' |
  'MEETING_RESCHEDULE' |
  'MEETING_ACTION_NOTIFICATION';

type Stage = {
  // eslint-disable-next-line no-unused-vars
  [_key in StagesKeys]: string;
}

const STAGES: Stage = {
  MEETING_SIGN_UP: 'MeetingSignUp',
  MEETING_CANCEL: 'MeetingCancel',
  MEETING_RESCHEDULE: 'MeetingReschedule',
  MEETING_ACTION_NOTIFICATION: 'MeetingActionNotification'
};

interface MeetingStatuses {
  sort: number;
  label: string;
  value: string;
}

type MeetingsResponse = PaginatedItemsResponse<MeetingResponse>;

interface MeetingsNextPageArgs {
  page: PageInfo | null;
  infiniteState: StateChanger;
}
interface MeetingsRequest {
  bookingId: string | number;
}

interface Slots {
  date: string;
  times: Array<string>;
}

interface State {
  stateMeetingAction?: string;
  meetings?: MeetingsResponse;
  meeting?: MeetingResponse;
  slots: Array<Slots>;
  statuses: Array<MeetingStatuses>;
  pageInfo: null | PageInfo;
}

interface Form {
  city: string;
  project: string;
  roomType: string;
  meetingType: string;
  date: string;
  time: string;
}

export const useMeetingsStore = defineStore('meetings', () => {
  const meetings = ref<State>({
    stateMeetingAction: undefined,
    meetings: undefined,
    meeting: undefined,
    slots: [],
    statuses: [],
    pageInfo: null
  });
  const {
    $axios,
    $sentry
  } = useContext();
  async function getMeetingStatuses (): Promise<void> {
    try {
      const url: string = 'api/meetings/statuses';
      const {
        data
      } = await $axios.get<Array<MeetingStatuses>>(url);

      meetings.value.statuses = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeetingStatuses ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getMeeting (meetingId: string): Promise<void> {
    try {
      const url:string = `api/meetings/${ meetingId }`;
      const {
        data
      } = await $axios.get<MeetingResponse>(url);

      meetings.value.meeting = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getMeetings (meetingsRequest: MeetingsRequest): Promise<void> {
    const { bookingId } = meetingsRequest;
    try {
      const url: string = 'api/meetings';
      const {
        data
      } = await $axios.get<MeetingsResponse>(url, { params: { booking_id: bookingId } });

      meetings.value.meetings = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeetings ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function nextPage ({ page, infiniteState }: MeetingsNextPageArgs): Promise<void> {
    try {
      if (!page?.next_page) {
        infiniteState.complete();

        return;
      }

      const response = await $axios.$get<MeetingsResponse>(page.next_page);

      if (!response.page_info) {
        throw new Error('page info not received');
      }

      if (response.result?.length) {
        meetings.value.pageInfo = response.page_info;

        if (meetings.value.meetings?.result) {
          meetings.value.meetings.result = [...meetings.value.meetings.result, ...response.result];
        }

        if (!meetings.value.pageInfo?.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      infiniteState.complete();
      throw error;
    }
  }

  async function getSlots ({
    meet,
    city,
    project
  }: {
    meet: string;
    city: string;
    project: string;
  }): Promise<void> {
    try {
      const url: string = 'api/meetings/slots';
      const {
        data
      } = await $axios.get(url, {
        params: {
          meet,
          city,
          project
        }
      });

      meetings.value.slots = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getSlots ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function signUpMeeting ({
    form,
    bookingId
  }: {
    form: Form;
    bookingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time,
        roomType
      } = form;

      const {
        data
      } = await $axios.post('api/meetings', {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`,
        propertyType: roomType,
        bookingId: Number(bookingId)
      });

      await getMeeting(data.id);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ signUpMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function rescheduleMeeting ({
    form,
    meetingId
  }: {
    form: Form;
    meetingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time
      } = form;

      await $axios.patch(`api/meetings/${ meetingId }`, {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`
      });

      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ rescheduleMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function cancelMeeting (meetingId: string): Promise<void> {
    try {
      await $axios.patch(`api/meetings/${ meetingId }/refuse`);
      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ cancelMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  function setStage (stage: StagesKeys): void {
    meetings.value.stateMeetingAction = STAGES[stage];
  }

  return {
    meetings,
    nextPage,
    getMeetingStatuses,
    getMeeting,
    getMeetings,
    getSlots,
    signUpMeeting,
    rescheduleMeeting,
    cancelMeeting,
    setStage
  };
}
);
