/* eslint-disable @typescript-eslint/explicit-function-return-type */
const accountApi = {
  account: {
    setPassword: (role: string) => `/api/${ role }/set_password`,
    resetAvailable: (role: string) => `/api/${ role }/reset_available`,
    changePassword: (role: string) => `/api/${ role }/change_password`,
    resetPassword: '/api/users/reset_password',
    getCautions: '/api/cautions',
    deleteCaution: (id: string | number) => `/api/cautions/${ id }`,
    register: (role: string) => `/api/${ role }/register`,
    checkContacts: '/api/users/check_user_contacts',
    joinAgency: (role: string) => `/api/${ role }/join_agency`,
    consultationTypes: '/api/users/consultation_type',

    documents: {
      acts: (role: string) => `api/agencies/${ role }/acts`,
      act: (role: string, id: string | number) => `api/agencies/${ role }/acts/${ id }`
    },

    agencies: {
      agencyExists: (agencyInn: string | number) => `/api/agencies/${ agencyInn }/exists`,
      agencyRetrieve: (agencyInn: string | number) => `/api/agencies/${ agencyInn }`,
      agencyGetContractData: (role: string) => `api/agencies/${ role }`,
      agencyFillContractData: (role: string) => `api/agencies/${ role }/fill`,
      getAgreementTypes: 'api/agencies/repres/agreements/types',
      agreements: (role: string) => `api/agencies/${ role }/agreements`,
      agreement: (role: string, id: string | number) => `api/agencies/${ role }/agreements/${ id }`
    },

    managers: {
      getManager: (city: string) => `/api/managers?city=${ city }`
    },

    clients: {
      specs: (role: string) => `/api/users/${ role }/clients/specs`,
      facets: (role: string) => `/api/users/${ role }/clients/facets`,
      lookup: (role: string) => `/api/users/${ role }/clients/lookup`
    },

    bookings: {
      specs: (role: string) => `/api/users/${ role }/bookings/specs`,
      specsV2: (role: string) => `/api/v2/users/${ role }/bookings/specs`,
      facets: (role: string) => `/api/users/${ role }/bookings/facets`,
      lookup: (role: string) => `/api/users/${ role }/bookings/lookup`
    },

    dependentRole: {
      // Когда все роли перейдут на v2 убрать лишний эндпоинт.
      list: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }`,
      clients: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/clients`,
      specs: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/specs`,
      facets: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/facets`,
      lookup: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/lookup`,
      clientDetail: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/clients/${ id }`,
      detail: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/${ id }`,
      check: (dependentRole: string, role: string) => `/api/v2/${ dependentRole }/${ role }/check`,
      checkDispute: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/check-dispute`,
      approval: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/approval/${ id }`,
      register: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/register`,
      rebound: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/rebound/${ id }`,
      bound: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/bound/${ id }`,
      unbound: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/unbound/${ id }`,
      bookings: (dependentRole: string, role: string) => `/api/${ dependentRole }/${ role }/bookings`,
      booking: (dependentRole: string, role: string, id: string | number) => `/api/${ dependentRole }/${ role }/bookings/${ id }`,
      uploadDocuments: (role: string, bookingId: string | number, id: string | number) => `/api/${ role }/bookings/${ bookingId }/upload-documents/${ id }`
    }
  }
};

export default accountApi;

export const accountApiConfig = accountApi.account;
