import {
  type MaybeRefOrGetter,
  toValue
} from '@vueuse/core';
import {
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  type ComputedRef,
  type Ref
} from 'vue';

// FIXME Это стырено из моего же участка в helpers в ui-kit. Нужно бы экспортировать оттуда всякие такие полезные мелочи
/**
 * Проверяет, есть ли `potentialParent` среди родителей `target`
 *
 * В какой-то степени аналог `Element.closest`, только без селекторов
 * @param target элемент, среди родителей которого будем искать `potentialParent`
 * @param potentialParent элемент, который будем искать среди родителей `target`
 * @returns `true` - если `potentialParent` - родитель любого уровня вложенности для `target`, иначе `false`
 */
export function isInParents (target: Element, potentialParent: Element): boolean {
  if (target === potentialParent) {
    return true;
  }

  while (target.parentElement !== potentialParent) {
    if (target.parentElement == null) {
      return false;
    }
    target = target.parentElement;
  }

  return true;
}


// FIXME: а это тоже стоит в ui-kit сунуть и экспортить оттуда
/**
 * Вызовет `functor`, если клик в документе не будет находиться внутри дерева потомков `parent` (включая его самого)
 *
 * Вызывать строго в setup (не хочу делать для options). Сама подпишется на клик, сама приберётся.
 * @param parent
 * @param functor
 */
export function useClickOutside (parent: MaybeRefOrGetter<Element | null>, functor: (e: Event) => void): void {
  function onDocumentClick (e: Event):void {
    const parentEl = toValue(parent);

    if (parentEl == null) {
      return;
    }
    const target = e.target as Element;

    if (!isInParents(target, parentEl)) {
      functor(e);
    }
  }

  onMounted(() => {
    document.addEventListener('click', onDocumentClick);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', onDocumentClick);
  });
}

interface UseFocusEventsArgs {
  onFocusInside(e: Event): void;
  onFocusOutside(e: Event): void;
}

export function useFocusEvents (parent: MaybeRefOrGetter<Element | null>, args: Partial<UseFocusEventsArgs>): void {
  if (args.onFocusInside == null && args.onFocusOutside == null) {
    throw new Error('Ошибка: ни один callback не предоставлен. [useFocusEvents]');
  }

  function onDocumentFocusIn (e: Event):void {
    const parentEl = toValue(parent);

    if (parentEl == null) {
      return;
    }
    const target = e.target as Element;

    if (isInParents(target, parentEl)) {
      args.onFocusInside?.(e);
    } else {
      args.onFocusOutside?.(e);
    }
  }

  onMounted(() => {
    document.addEventListener('focusin', onDocumentFocusIn);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('focusin', onDocumentFocusIn);
  });
}

const defaultVisibleFrame = {
  opacity: 1
};
const defaultInvisibleFrame = {
  opacity: 0
};

interface UseOpenHideAnimationReturn {
  animate(forward: boolean): void;
  isHidden: ComputedRef<boolean>;
}

type Keyframes = Parameters<Element['animate']>[0]

export function useOpenHideAnimation (elRef: Ref<Element | null | undefined>, isHiddenDefault = false): UseOpenHideAnimationReturn {
  let animation: ReturnType<Element['animate']> | null = null;
  const isHidden = ref(isHiddenDefault);

  function getFrames (forward: boolean): Keyframes {
    return forward ? [defaultInvisibleFrame, defaultVisibleFrame] : [defaultVisibleFrame, defaultInvisibleFrame];
  }

  function animate (forward: boolean):void {
    const el = elRef.value;

    if (el == null) {
      return;
    }

    animation?.cancel();

    if (forward) {
      isHidden.value = false;
    }

    animation = el.animate(getFrames(forward), {
      duration: 200
    });

    if (!forward) {
      animation.addEventListener('finish', () => {
        isHidden.value = true;
      });
    }
  }

  return {
    animate,
    isHidden: computed(() => isHidden.value)
  };
}

/**
 * Декоратор, ограничивающий срабатывание функции частотой кадров.
 */
export const rAFdebounce = <TArgs extends Array<unknown>>(fn: (...args: TArgs) => unknown): (...args: TArgs) => void => {
  let frame: ReturnType<typeof requestAnimationFrame> | undefined;

  return (...args: TArgs): void => {
    if (frame != null) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => {
      fn(...args);
    });
  };
};
