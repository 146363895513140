import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.Layout
  }, [_c(_setup.NHeader, {
    class: _vm.$style.header,
    on: {
      "menu-button-click": _setup.menuLogic.open
    }
  }), _vm._v(" "), _c(_setup.BackdropOverlay, {
    class: _vm.$style.backdropOverlay,
    attrs: {
      "visible": _setup.backdropVisible
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.menuLogic.close.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c('div', _vm._b({
    ref: "menuRef",
    class: _vm.$style.menuWrapper
  }, "div", _vm._d({}, [_setup.iosScrollShouldLockAttributeNameCrutch, true])), [_setup.screen.isDesktop ? _c(_setup.MenuDesktopActivator) : _vm._e(), _vm._v(" "), _c(_setup.NMenu, {
    ref: "menuRef",
    class: [_vm.$style.menu, _defineProperty({}, _vm.$style.visible, _setup.menuVisible)],
    on: {
      "menu-item-click": _setup.onMenuItemClick
    }
  })], 1), _vm._v(" "), _c('div', [_vm._t("default")], 2), _vm._v(" "), _c('client-only', [_c(_setup.NFooter)], 1), _vm._v(" "), _c(_setup.NScrollUpButton), _vm._v(" "), _c('div', {
    class: _vm.$style.modalPlace
  }, [_c('TheModal'), _vm._v(" "), _c('portalTarget', {
    attrs: {
      "name": "layout",
      "multiple": ""
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };