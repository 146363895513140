import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

let nuxtApp: Context | undefined;

export default defineNuxtPlugin((_nuxtApp: Context) => {
  nuxtApp = _nuxtApp;
});

export const useVuexStore = (): NonNullable<typeof nuxtApp>['store'] => {
  if (!nuxtApp?.store) {
    throw new Error('Vuex Store is not defined :(');
  }

  return nuxtApp?.store;
};


/**
 * В отличие от useContext, работает даже вне setup контекста
 */
export const useIsomorphicContext = (): Context => nuxtApp!;


export const useAxios = (): NuxtAxiosInstance => {
  if (nuxtApp == null) {
    throw new Error('nuxtApp was null');
  }

  return nuxtApp.$axios;
};
