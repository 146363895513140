import { getLogger } from '@@/shared/services/Logger';

export function fullLog (error: unknown, message: string): void {
  getLogger('console').log('error', [message, '\n', error]);
  getLogger().log('error', error);
}

type TFunction = ((...args: Array<never>)=>unknown)

export function fullLogDecorate<TFn extends TFunction> (fn: TFn, message: string, rethrow = false): TFn {
  // возможно, немного преждевременная и "оптимизация на палочках", но мне показалось так лучше, чем каждый раз if-ать
  const result = rethrow
    ? (async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        fullLog(error, message);
        throw error;
      }
    }) as TFn
    : (async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        fullLog(error, message);
      }
    }) as TFn;

  return result;
}

/**
 * Задекорирует все функции в полях `actions` при помощи {@link fullLogDecorate} на логирование {@link fullLog}
 * @param actions объект с методами, которые нужно задекорировать
 * @param filename для удобства отладки - имя файла, где используется
 * @param rethrow выбрасывать ли исключение после логирование дальше
 */
export function decorateApiActions<TActions extends Record<string, TFunction>> (actions: TActions, filename: string, rethrow = false): TActions {
  const decorated = {} as TActions;
  for (const key in actions) {
    decorated[key] = fullLogDecorate(actions[key], `🚀 ~ file: ${ filename } ~ ${ key } ~ error`, rethrow);
  }

  return decorated;
}
