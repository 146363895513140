var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('header', {
    class: _vm.$style.Header
  }, [_c('div', {
    class: _vm.$style.leftSide
  }, [_c('NuxtLink', {
    class: _vm.$style.logoLink,
    attrs: {
      "to": "/",
      "aria-label": "Главная"
    }
  }, [_c('img', {
    class: _vm.$style.logoImage,
    attrs: {
      "src": "/images/developer-logo.svg",
      "alt": "Страна девелопмент"
    }
  })])], 1), _vm._v(" "), _c('nav', {
    class: _vm.$style.rightSide,
    attrs: {
      "aria-label": "Дополнительная"
    }
  }, [_setup.screen.isDesktop ? [_c(_setup.HeaderButton, {
    class: _vm.$style.button,
    attrs: {
      "icon": "i-contacts-book-fill",
      "text": "Контакты",
      "href": "/contacts"
    }
  }), _vm._v(" "), _c(_setup.HeaderButton, {
    class: _vm.$style.button,
    attrs: {
      "icon": "i-article-fill",
      "text": "Документы",
      "href": "/documents"
    }
  })] : _vm._e(), _vm._v(" "), _setup.screen.isTabletOrDesktop ? _c(_setup.HeaderProfileButton) : _vm._e(), _vm._v(" "), _c(_setup.HeaderButton, {
    class: [_vm.$style.button, _vm.$style.notificationsButton],
    attrs: {
      "icon": "i-notification-bell-fill"
    }
  }), _vm._v(" "), _setup.screen.isDesktop ? _c(_setup.HeaderHelpButton) : _vm._e(), _vm._v(" "), _setup.screen.isMobileOrTablet ? _c(_setup.HeaderButtonMenu, {
    class: _vm.$style.menuButton,
    nativeOn: {
      "click": function click($event) {
        return _setup.emit('menu-button-click');
      }
    }
  }) : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };